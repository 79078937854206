import "./Gestion.css"
import GestionInfClient from "../GestionInfClient/GestionInfClient";
import GestionFacturas from "../GestionFacturas/GestionFacturas";


const Gestion = ({cliente,listaCuotas,setListaCuotas})=>{

    return(
        <div className="container mt-5 mb-5">
            <h4 className="title-section mb-5">INFORMACIÓN DEL CRÉDITO</h4>
            <GestionInfClient cliente={cliente}/>
            <div className="container p-0 col-10 mb-3 mt-3 info-table-color">
                <div className="col-4 text-left">
                    <span className="material-symbols-outlined align-middle cuota_vencida">grid_view</span>
                    <span className="align-middle ml-1">Cuotas Vencidas</span>
                </div>
            </div>
            <GestionFacturas listaCuotas={listaCuotas} setListaCuotas={setListaCuotas}/>
        </div>
    );
}

export default Gestion;