import img1 from  "../../imgs/about-bg.jpg";
import img_card1 from "../../imgs/img_CardN/about-mission.jpg"
import img_card2 from "../../imgs/img_CardN/about-plan.jpg"
import img_card3 from "../../imgs/img_CardN/about-vision.jpg"
import "./Nosotros.css";
const Nosotros=()=>{
    return(
        <>
            <img src={img1} alt=''/>
            <div className='container mt-5'>
                <h4 className="title-section">SOBRE NOSOTROS</h4>
                <p className='mt-4'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Natus minima perspiciatis iste harum necessitatibus est sunt a iure aperiam ea, suscipit eveniet aut sapiente enim placeat libero eligendi soluta nihil?</p>
                <div className="row justify-content-center mt-5 mb-5">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-4 mt-3 mb-3">
                        <div className="card">
                            <img className="card-img-top" src={img_card1} alt="" />
                            <div className="card-body">
                                <div className="cont-icon"><span className="material-symbols-outlined">speed</span></div>
                                <h5 className="card-title mt-4">Nuestra misión</h5>
                                <p className="card-text text-left">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repudiandae beatae autem ex asperiores velit quas rerum dolorum eligendi distinctio suscipit excepturi magni, quidem dolore molestiae iusto voluptates consectetur repellat et!</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-4 mt-3 mb-3">
                        <div className="card">
                            <img className="card-img-top" src={img_card2} alt="" />
                            <div className="card-body">
                                <div className="cont-icon"><span className="material-symbols-outlined">list_alt</span></div>
                                <h5 className="card-title mt-4">Nuestra misión</h5>
                                <p className="card-text text-left">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repudiandae beatae autem ex asperiores velit quas rerum dolorum eligendi distinctio suscipit excepturi magni, quidem dolore molestiae iusto voluptates consectetur repellat et!</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-4 mt-3 mb-3">
                        <div className="card">
                            <img className="card-img-top" src={img_card3} alt="" />
                            <div className="card-body">
                                <div className="cont-icon"><span className="material-symbols-outlined">visibility</span></div>
                                <h5 className="card-title mt-4">Nuestra misión</h5>
                                <p className="card-text text-left">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repudiandae beatae autem ex asperiores velit quas rerum dolorum eligendi distinctio suscipit excepturi magni, quidem dolore molestiae iusto voluptates consectetur repellat et!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export default Nosotros