import "./CardsIn.css"

const CardsIn= ({title,texto,icon_class,icon_text})=>{
    return(
        <div className="card col-12 col-sm-12 col-md-4 col-lg-4">
          <div className="card-body p-5">
            <div className="text-left"><span className={icon_class}>{icon_text}</span></div>
            <h5 className="card-title text-left">{title}</h5>
            <p className="card-text text-justify">{texto}</p>
          </div>
        </div>
    );
}

export default CardsIn;