import "./Carrusel.css"
import img1 from "../../imgs/intro-carousel/1.jpg"
import img2 from "../../imgs/intro-carousel/2.jpg"
import img3 from "../../imgs/intro-carousel/3.jpg"
import img4 from "../../imgs/intro-carousel/4.jpg"
import img5 from "../../imgs/intro-carousel/5.jpg"

const Carrusel=({cliente,isvisibleCodigo,infoCliente, setInfoCliente, setIsVisibleGSC, HandleBuscar,HandleValidar})=>{
  const HandleDbClick =(e)=>{
    setIsVisibleGSC(false);
    setInfoCliente({...infoCliente,...{[e.target.name]:""}});
  }

  const handleChange=(e)=>{
    setInfoCliente({...infoCliente,...{[e.target.name]:e.target.value}})
  }

    return(
        <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img className="d-block w-100" src={img1} alt="First slide"/>
          </div>
          <div className="carousel-item">
            <img className="d-block w-100" src={img2} alt="Second slide"/>
          </div>
          <div className="carousel-item">
            <img className="d-block w-100" src={img3} alt="Third slide"/>
          </div>
          <div className="carousel-item">
            <img className="d-block w-100" src={img4} alt="Third slide"/>
          </div>
          <div className="carousel-item">
            <img className="d-block w-100" src={img5} alt="Third slide"/>
          </div>

            
          <div className="carousel-caption h-75 d-none d-md-block">
                <h5>Lorem ipsum dolor sit amet?</h5>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque odio architecto sunt dolores ratione delectus nihil voluptatibus mollitia quisquam dolor corrupti quam corporis soluta deleniti expedita, magnam fugiat laborum voluptas.</p>
          </div>
          <div className="carousel-caption h-50 d-none d-flex cont-info">
              
                <div className="d-inline-block col-12 col-md-5 floating mb-2">
                  <input  value={infoCliente.cedula} onDoubleClick={HandleDbClick} onChange={handleChange} type="number" className="form-control" id="cedula" name="cedula"/>
                  <label className={"label-floating"+(infoCliente.cedula!=="" ?" isFloting" : "")} htmlFor="cedula">Identificación</label>
                </div>
                <div className="d-inline-block col-12 col-md-5 floating mb-2">
                  <input  value={infoCliente.nrocredito} onDoubleClick={HandleDbClick} onChange={handleChange} type="text" className="form-control" id="nrocredito" name="nrocredito"/>
                  <label className={"label-floating"+(infoCliente.nrocredito!=="" ?" isFloting" : "")} htmlFor="nrocredito">Nro Aprobación</label>
                </div>
                <button onClick={HandleBuscar} className="btn-consult btn col-10 col-md-1 mb-2"><span className="material-symbols-outlined">search</span></button>
          </div>
          {
            isvisibleCodigo &&
            <div className="carousel-caption h-25 d-block">
              <p>Código de Confirmación enviado al correo <strong>{cliente.correo}</strong></p>
              <div className="d-flex cont-validar justify-content-center">
                  
                    <input autoComplete="off" value={infoCliente.dt_1} onDoubleClick={HandleDbClick} onChange={handleChange} type="text" maxLength="1" className="form-control col-1 input-cod"  name="dt_1"/>
                    <input autoComplete="off" value={infoCliente.dt_2} onDoubleClick={HandleDbClick} onChange={handleChange} type="text" maxLength="1" className="form-control col-1 input-cod"  name="dt_2"/>
                    <input autoComplete="off" value={infoCliente.dt_3} onDoubleClick={HandleDbClick} onChange={handleChange} type="text" maxLength="1" className="form-control col-1 input-cod"  name="dt_3"/>
                    <input autoComplete="off" defaultValue={infoCliente.dt_s} type="text" className="form-control col-1 input-cod" disabled  name="dt_s"/>
                    <input autoComplete="off" value={infoCliente.dt_4} onDoubleClick={HandleDbClick} onChange={handleChange} type="text" maxLength="1" className="form-control col-1 input-cod"  name="dt_4"/>
                    <input autoComplete="off" value={infoCliente.dt_5} onDoubleClick={HandleDbClick} onChange={handleChange} type="text" maxLength="1" className="form-control col-1 input-cod"  name="dt_5"/>
                    <input autoComplete="off" value={infoCliente.dt_6} onDoubleClick={HandleDbClick} onChange={handleChange} type="text" maxLength="1" className="form-control col-1 input-cod"  name="dt_6"/>
                      
                    <button onClick={HandleValidar} className="btn-consult btn col-2 col-md-1"><span className="material-symbols-outlined">done</span></button>
              </div>
            </div>
          }

        </div>
        <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
    );
}

export default Carrusel;