import React, { useEffect, useRef } from 'react';

const MyComponent = ({valor}) => {
  const scriptRef = useRef(null);

  useEffect(() => {
    // Crea el elemento script
    const scriptElement = document.createElement('script');
    scriptElement.src = 'https://checkout.wompi.co/widget.js';
    scriptElement.setAttribute('data-public-key', 'pub_test_Q5yDA9xoKdePzhSGeVe9HAez7HgGORGf');
    scriptElement.setAttribute('data-currency', 'COP');
    scriptElement.setAttribute('data-amount-in-cents', valor);
    scriptElement.setAttribute('data-merchant-logo_url', "https://theme.zdassets.com/theme_assets/9116742/43856dd2accbb91772fcdfc29226b196de480d79.svg");
    scriptElement.setAttribute('data-reference', 'GKQPZCY78ssVUhysdfU');
    scriptElement.setAttribute('data-redirect-url', 'http://localhost:3000');
    scriptElement.setAttribute('data-render', 'button');

    // Agrega el script al DOM
    if (scriptRef.current) {
        if(scriptRef.current.children.length>0){
            scriptRef.current.children[0].remove();
        }
      scriptRef.current.appendChild(scriptElement);
    }

    // Limpia el script al desmontar el componente
    return () => {
      if (scriptRef.current) {
        scriptRef.current.removeChild(scriptElement);
      }
    };
  }, [valor]);

  return (
    <div>
      <div ref={scriptRef}></div>
    </div>
  );
};

export default MyComponent;
