import logo from "../../imgs/FIABLE_SIN_FONDO.png"
import "./NavBars.css"

const NavBars = ()=>{
    window.addEventListener("scroll",()=>{
        if(window.scrollY>=200){
            document.getElementById("cont-nav").style.background="rgba(0, 0, 0, 0.897)"
        }
        else{
            document.getElementById("cont-nav").style.background=""
        }
    })
    return(
        <div id="cont-nav" className='cont-nav row fixed-top'>
            <div className='p-2 m-auto ps-4 pe-4 col-6 col-sm-2 col-md-2 col-lg-5'>
            <a href="#HOME" className='col-2 h7 m-auto'><img src={logo} alt="Logo" /></a>
            </div>
            <div className='navBarsMenu m-auto p-2 ps-4 pe-4 col-6 col-sm-10 col-md-10 col-lg-7'>
                <div className='row pr-4'>
                    <a href="#HOME" className='col-2 h7 m-auto'>HOME</a>
                    <a href="#NOSOTROS" className='col-2 h7 m-auto'>NOSOTROS</a>
                    <a href="#SERVICIOS" className='col-2 h7 m-auto'>SERVICIOS</a>
                    <a href="#PORTAFOLIO" className='col-2 h7 m-auto'>PORTAFOLIO</a>
                    <a href="#EQUIPO" className='col-2 h7 m-auto'>EQUIPO</a>
                    <a href="#CONTACT" className='col-2 h7 m-auto'>CONTACT</a>
                </div>
            </div>
        </div>
    );
}

export default NavBars;