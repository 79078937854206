import './App.css';
import NavBars from './components/NavBars/NavBars';
import Carrusel from './components/Carrusel/Carrusel';
import CardsIn from './components/CardsIn/CardsIn'
import Nosotros from './components/Nosotros/Nosotros';
import Servicios from './components/Servicios/Servicios';
import { useEffect, useState } from 'react';
import Gestion from './components/Gestion/Gestion';
import Footer from './components/Footer/Footer';
import { URL_ApiConsultarGSC, URL_LOGIN, datosLogin} from './config';
// import { URL_EnviarCorreo } from './config';

let initialValue = {
  nombre:"",
  iden:"",
  tipoDc:"",
  direccion:"",
  telefono:"",
  correo:"",
  factura:""
}

let initValClient ={
  cedula:"",
  nrocredito:"",
  dt_1:"",
  dt_2:"",
  dt_3:"",
  dt_s:"--",
  dt_4:"",
  dt_5:"",
  dt_6:"",
  codigoValidar:""
}

let response = {
  nombre:"",
  iden:"",
  tipoDc:"",
  direccion:"",
  telefono:"",
  correo:"",
  factura:""
}



function App() {
  const [infoCliente, setInfoCliente] = useState(initValClient);
  const [cliente, setCliente] = useState(initialValue);
  const [listaCuotas, setListaCuotas] = useState([]);
  const [isVisibleGSC,setIsVisibleGSC] =useState(false);
  const [isvisibleCodigo,setIsVisibleCodigo] = useState(false);
  
  const HandleBuscar =()=>{
    setIsVisibleGSC(false);
      if(infoCliente.cedula!=="" && infoCliente.nrocredito){
        let fecha2 = new Date();
        let fecha =fecha2.getFullYear()+"-"+(fecha2.getMonth().toString().length===1 ? "0"+(fecha2.getMonth()+1) : (fecha2.getMonth()+1))+"-"+(fecha2.getDate().toString().length===1 ? "0"+fecha2.getDate() : fecha2.getDate())

        fetch(URL_LOGIN,{
          method:"POST",
          body: JSON.stringify(datosLogin),
          headers:{
            "Content-Type": "application/json"
          }
        })
        .then(res=>res.json())
        .then(res=>{
          if(!res.Error){
            let token = res.Respuesta;
            fetch(URL_ApiConsultarGSC,{
              method:"POST",
              body:JSON.stringify({
                "Identificacion": infoCliente.cedula,
                "fecha": fecha
              }),
              headers:{
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
              }
            })
            .then(res=> res.json())
            .then(res=>{
              //validar si la respuesta fue eun errror res.error
              if(!res.Error){
                
                let factura = res.Factura.filter(el =>el.nroaprobacion ===infoCliente.nrocredito)
                if(factura.length>0){
                  let data = res.Resultado.filter(el=> el.factura===factura[0].numfactura);
                  response.nombre=data[0].razonsocial;
                  response.correo=data[0].Correo;
                  response.direccion=data[0].Direccion;
                  response.iden=data[0].Identificacion;
                  response.telefono=data[0].telefono;
                  response.tipoDc=data[0].TipoDocumento;
                  response.factura=infoCliente.nrocredito;
                  setCliente(response)
                  setListaCuotas(data.map(cuota=>{
                    return(
                      {Cuota:cuota.cuota,
                      Vencimiento:cuota.vencimiento_cuota,
                      ValorC:cuota.vlrcuota,
                      Interes:cuota.interes,
                      SaldoC:(cuota.saldo+cuota.interes),
                      ValorP:(cuota.saldo+cuota.interes),
                      Pagar:!cuota.cancelada}
                    );
                  }))
                  
                  /*
                  Lo siguiente es para enviar el correo de confirmacion, si se descomenta hay que
                  comentar la siguiente linea despues del fin: setIsVisibleGSC(true); y 
                  tambien hay que descomentar el import de URL_EnviarCorreo
                  */
                  // const formData = new FormData();
                  // formData.append("email",response.correo)
                  // fetch(URL_EnviarCorreo,{
                  //   method:"POST",
                  //   body: formData
                  // })
                  // .then(res=>res.json())
                  // .then(res=>{
                  //   if(!res.error){
                  //     alert(res.mensaje);
                  //     setInfoCliente({...infoCliente,...{codigoValidar:res.codigo}})
                  //     setIsVisibleCodigo(true)
                  //   }
                  //   else{
                  //     alert(res.mensaje);
                  //   }
                  // })
                  // .catch(res=>console.log(res))
                  
                  /*Fin de envio de correo*/
                  setIsVisibleGSC(true);
    
                }else{
                  alert("Error consultando la cartera verifique el número del crédito.");
                }
              }
              else{
                alert(res.Mensaje)
              }
            })
            .catch(res=>console.log(res))

          }
          else{
            console.log(res)
          }
        })
        .catch(res=>console.log(res))
        
      }
  }

  const HandleValidar=()=>{
    const {dt_1,dt_2,dt_3,dt_4,dt_5,dt_6,codigoValidar} = infoCliente;
    if(`${dt_1}${dt_2}${dt_3}${dt_4}${dt_5}${dt_6}` === codigoValidar.replace('-','')){
      setIsVisibleGSC(true);
      setIsVisibleCodigo(false);
      setInfoCliente({...infoCliente,...{ dt_1:"",dt_2:"",dt_3:"",dt_4:"",dt_5:"",dt_6:""}});
    }
    else{
      alert("Código invalido.");
    }
  }
  
  useEffect(()=>{
    if(isVisibleGSC){
      window.location="#Form_GSC";
    }
  },[isVisibleGSC])

  return (
    
    <div className="container-fluid text-center min-vw-100 p-0">
      <NavBars/>
      <div id='HOME' className='cont-carrusel'>
        <Carrusel cliente={cliente} isvisibleCodigo={isvisibleCodigo} infoCliente={infoCliente} setInfoCliente={setInfoCliente} setIsVisibleGSC={setIsVisibleGSC} HandleBuscar={HandleBuscar} HandleValidar={HandleValidar}/>
      </div>
      <div className='row card-in'>
        <CardsIn 
          icon_class="material-symbols-outlined"
          icon_text="menu_book"
          title="Lorem Ipsum Delino" 
          texto="Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident"
        />
        <CardsIn 
          icon_class="material-symbols-outlined"
          icon_text="alarm"
          title="Dolor Sitema" 
          texto="Minim veniam, quis nostrud ejercicio ullamco laboris nisi ut aliquip ex ea commodo consequat tarad limino ata"
          />
        <CardsIn 
          icon_class="material-symbols-outlined"
          icon_text="favorite"
          title="Sed ut perspiciatis" 
          texto="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur"
        />
      </div>
      {
        isVisibleGSC 
        ?
        <div id='Form_GSC' className='row cont-GSC'>
          <Gestion cliente={cliente} listaCuotas={listaCuotas} setListaCuotas={setListaCuotas}/>
        </div>
        :
        <>
        <div id='NOSOTROS' className='row cont-Nosotros'>
            <Nosotros/>
        </div>
        <div id='SERVICIOS' className='row cont-servicios'>
          <Servicios/>
        </div>
        </>
      }
      <div className='row cont-footer'>
        <Footer/>
      </div>
    </div>
    
  );
}

export default App;
