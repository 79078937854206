import "./Footer.css";
import logo from "../../imgs/FIABLE_SIN_FONDO.png"
import twitter from "../../imgs/Iconos-footer/twitter.png"
import facebook from "../../imgs/Iconos-footer/facebook.png"
import instagram from "../../imgs/Iconos-footer/instagram.png"
import linkedin from "../../imgs/Iconos-footer/linkedin.png"

const Footer=()=>{
    return(
        <div className="container text-left pl-0 pb-4 pt-4">
            <div className="row justify-content-center">
                <div className="col-10 col-sm-12 col-md-6 col-lg-4 pb-3">
                    <img src={logo} alt="" />
                    <p>Cras fermentum odio eu feugiat lide par naso tierra. Justo eget nada terra videa magna derita valies darta donna mare fermentum iaculis eu non diam phasellus. Scelerisque felis imperdiet proin fermentum leo. Amet volutpat consequat mauris nunc congue.</p>
                </div>

                <div className="col-10 col-sm-12 col-md-6 col-lg-4 pb-3 mt-4 pl-4">
                    <h3>ENLACES ÚTILES</h3>
                    <div className="cont-enlaces">
                        <span className="material-symbols-outlined">chevron_right</span>
                        <a href="#HOME">Home</a>
                    </div>
                    <div className="cont-enlaces">
                        <span className="material-symbols-outlined">chevron_right</span>
                        <a href="#NOSOTROS">Sobre nosotros</a>
                    </div>
                    <div className="cont-enlaces">
                        <span className="material-symbols-outlined">chevron_right</span>
                        <a href="#SERVICIOS">Servicios</a>
                    </div>
                    <div className="cont-enlaces">
                        <span className="material-symbols-outlined">chevron_right</span>
                        <a href="#HOME">Términos de servicio</a>
                    </div>
                    <div className="cont-enlaces">
                        <span className="material-symbols-outlined">chevron_right</span>
                        <a href="#HOME">Política de privacidad</a>
                    </div>
                </div>
                <div className="col-10 col-sm-12 col-md-6 col-lg-4 pb-3 mt-4 pl-4">
                    <h3>CONTÁCTENOS</h3>
                    <div className="pr-5">
                        <div className="pr-5 pb-3">A108 Adam Street Nueva York, NY 535022 Estados Unidos</div>
                        <div className="pb-3"><strong>Teléfono:</strong> +1 5589 55488 55</div>
                        <div><strong>Correo electrónico:</strong> info@example.com</div>
                        <div className="cont-icon-footer">
                            <a className="d-inline-block" href="https://www.twitter.com/"><img src={twitter} alt="" /></a>
                            <a className="d-inline-block" href="https://www.facebook.com/"><img src={facebook} alt="" /></a>
                            <a className="d-inline-block" href="https://www.instagram.com/"><img src={instagram} alt="" /></a>
                            <a className="d-inline-block" href="https://www.linkedin.com/"><img src={linkedin} alt="" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer