import { useEffect, useState } from "react";
import {Money} from "../../FuntionsHelp/FuntionHelp"
import "./GestionFacturas.css"
import MyComponent from "../Wompi";


const GestionFacturas=({listaCuotas,setListaCuotas})=>{
    const [listaPagar,setListaPagar] = useState([]);
    const [activePagar,setActivePagar] = useState(false);
    const [valor,setValor] = useState(1)
    let i=0;

    useEffect(()=>{
        let valor2 = 0;
        listaPagar.map(el=> valor2+=parseFloat(el.valor));
        setValor(parseInt(valor2.toFixed(2)*100))
    },[listaPagar])

    const editarValor=(pagar,e)=>{
        if(pagar){
            e.target.parentNode.children[0].classList.remove("input-visible");
            e.target.parentNode.children[0].classList.add("input-oculto");
            e.target.parentNode.children[1].classList.remove("input-oculto");
            e.target.parentNode.children[1].classList.add("input-visible");
            e.target.parentNode.children[1].focus()
        }
    }

    const Handlechange=(e,Vencimiento,valor)=>{
        let id = parseFloat(e.target.getAttribute("id").replace("check_",""));

        if(id ===1 || id===i || (document.getElementById(`check_${id-1}`).checked && e.target.checked ) || (!document.getElementById(`check_${id+1}`).checked && !e.target.checked )){
            if((id ===1 && !document.getElementById(`check_${id+1}`).checked) || (id !==1 && id !==i)){
                if(listaPagar.length===0 || listaPagar.filter(el=> el.Vencimiento === Vencimiento).length===0){
                    setListaPagar([...listaPagar,{Vencimiento,valor}])
                }
                else{
                    setListaPagar(listaPagar.filter((el)=>el.Vencimiento !==Vencimiento))
                }
            }
            else if(id===i && document.getElementById(`check_${id-1}`).checked){
                if(listaPagar.length===0 || listaPagar.filter(el=> el.Vencimiento === Vencimiento).length===0){
                    setListaPagar([...listaPagar,{Vencimiento,valor}])
                }
                else{
                    setListaPagar(listaPagar.filter((el)=>el.Vencimiento !==Vencimiento))
                }
            }
            else{
                e.target.checked= !e.target.checked;    
            }
        }
        else{
            e.target.checked= !e.target.checked;
        }
        
        // if(listaPagar.length===0 || listaPagar.filter(el=> el.Vencimiento === Vencimiento).length===0){
        //     setListaPagar([...listaPagar,{Vencimiento,valor}])
        // }
        // else{
        //     setListaPagar(listaPagar.filter((el)=>el.Vencimiento !==Vencimiento))
        // }
        // setValor(0);
    }
    
    const PagarTodo =()=>{
        if(listaPagar.length>0){
            if(window.confirm("Seguro desea pagar todas las cuotas seleccionadas.")){
                let valor = 0;
                listaPagar.map(el=> valor+=parseFloat(el.valor));
                if(valor>0){
                    alert("Cuotas a pagar: "+Money({value:valor.toFixed(2)}));
                    setActivePagar(true);
                    setValor(parseInt(valor.toFixed(2)*100))
                }
                else{
                    alert("El valor a pagar debe ser mayor a 0.");    
                }
            }
        }
        else{
            alert("No tiene cuotas seleccionadas.");
        }
    }

    const changeEditarValor=(e)=>{
        e.target.parentNode.children[0].classList.remove("input-oculto");
        e.target.parentNode.children[0].classList.add("input-visible");
        e.target.parentNode.children[1].classList.remove("input-visible");
        e.target.parentNode.children[1].classList.add("input-oculto");
    }

    const handleChangeValorP=(e,Vencimiento)=>{
        setListaCuotas(listaCuotas.map(el=>{
            if(el.Vencimiento===Vencimiento){
                el.ValorP=e.target.value;
            }
            return el;
        }));
        setListaPagar(listaPagar.map(el=>{
            if(el.Vencimiento===Vencimiento){
                el.valor=e.target.value;
            }
            return el;
        }))
    }

    const SelectAll =()=>{
        let listNew =[];
        if(document.querySelectorAll("[id^='check_']").length === listaPagar.length){
            Array.from(document.querySelectorAll("[id^='check_']")).forEach(el=>{ 
                el.checked=false;
            })
        }
        else{
            Array.from(document.querySelectorAll("[id^='check_']")).forEach(el=>{ 
                el.checked=true;
                listNew.push({Vencimiento:el.name,valor:el.value});
            })
        }
        setListaPagar(listNew);
    }
    
    const PagarCuota=(valor)=>{
        alert("Pagar cuota: "+valor)
    }

    return(
        <>
            <div id="tablaCuotas" className="table-responsive p-0 col-12 col-sm-12 col-md-12 col-lg-10 m-auto">
                <table className="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th className="SelectAll" onClick={SelectAll} scope="col">Seleccionar</th>
                            <th scope="col">N° Cuota</th>
                            <th scope="col">Vencimiento</th>
                            <th scope="col">Valor Cuota</th>
                            <th scope="col">Interes</th>
                            <th scope="col">Saldo Cuota</th>
                            <th scope="col">Valor Pagar</th>
                            <th scope="col">Pagar</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        listaCuotas.length>0 
                        ?
                            
                            listaCuotas.map((el)=>{
                                i = (el.Pagar ? i+1 : i);
                                return(
                                    <tr key={el.Vencimiento} className={!el.Pagar ? "cuota-paga" : (el.Interes>0 ? " cuota_vencida" : "") }>
                                        <td>
                                            <input disabled={!el.Pagar ? "disabled" : ""} onChange={(e)=>Handlechange(e,el.Vencimiento,el.ValorP)} type="checkbox" name={el.Vencimiento} value={el.ValorP} id={!el.Pagar ? el.Vencimiento : "check_"+i}/>
                                        </td>
                                        <td>{el.Cuota}</td>
                                        <td>{el.Vencimiento}</td>
                                        <td>{Money({value:el.ValorC})}</td>
                                        <td>{Money({value:el.Interes})}</td>
                                        <td>{Money({value:el.SaldoC})}</td>
                                        <td className="text-center">
                                            <div className="pointer" onDoubleClick={(e)=>editarValor(el.Pagar,e)}>{Money({value:el.ValorP})}</div>
                                            <input onChange={(e)=>handleChangeValorP(e,el.Vencimiento)} onBlur={changeEditarValor} className="input-oculto m-auto text-center" type="number" value={el.ValorP}/>
                                        </td>
                                        <td>
                                            {
                                                el.Pagar 
                                                ? <span onClick={()=>PagarCuota(el.ValorP)} data-id={el.Vencimiento} className="c-no-pagada material-symbols-outlined">payments</span>
                                                : <span className="c-pagada material-symbols-outlined">done_outline</span>
                                            }
                                        </td>
                                    </tr>
                                );
                                
                            })
                        : 
                        <tr>
                            <td colSpan="5">No hay resultado</td>
                        </tr>
                    }
                    </tbody>
                </table>
            </div>
            {/* <button onClick={PagarTodo} className="btn Pagar_T mt-3 mb-3">Pagar</button> */}
            {
                valor>0 &&
                <MyComponent valor={valor}/>
            }
        </>
    );
}

export default GestionFacturas;