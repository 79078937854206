import "./GestionInfClient.css"
const GestionInfClient =({cliente})=>{
    return(
        <>
            <div className="row justify-content-center  mb-3 mt-3">
                <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6 mb-4">
                    <div className="input-group floating">
                        <span className="span-icon material-symbols-outlined">person</span>
                        <input disabled defaultValue={cliente.nombre}  name="nombre" id="nombre" type="text" className="form-control"/>
                        <label className={"label-floating"+(cliente.nombre!=="" ?" isFloting" : "")} htmlFor="nombre">Nombre</label>
                    </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-md-3 col-lg-3 mb-4">
                    <div className="input-group floating">
                        <span className="span-icon material-symbols-outlined">fingerprint</span>
                        <input disabled defaultValue={cliente.iden}  name="iden" id="iden" type="text" className="form-control"/>
                        <label className={"label-floating"+(cliente.iden!=="" ?" isFloting" : "")} htmlFor="iden">Identificación</label>
                    </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-md-3 col-lg-3 mb-4">
                    <div className="input-group floating">
                        <span className="span-icon material-symbols-outlined">badge</span>
                        <input disabled defaultValue={cliente.tipoDc}  name="tipoDc" id="tipoDc" type="text" className="form-control"/>
                        <label className={"label-floating"+(cliente.tipoDc!=="" ?" isFloting" : "")} htmlFor="tipoDc">Tipo Documento</label>
                    </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6 mb-4">
                    <div className="input-group floating">
                        <span className="span-icon material-symbols-outlined">domain</span>
                        <input disabled defaultValue={cliente.direccion}   name="direccion" id="direccion" type="text" className="form-control"/>
                        <label className={"label-floating"+(cliente.direccion!=="" ?" isFloting" : "")} htmlFor="direccion">Dirección</label>
                    </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-md-3 col-lg-3 mb-4">
                    <div className="input-group floating">
                        <span className="span-icon material-symbols-outlined">credit_card</span>
                        <input disabled defaultValue={cliente.factura}  name="factura" id="factura" type="text" className="form-control"/>
                        <label className={"label-floating"+(cliente.factura!=="" ?" isFloting" : "")} htmlFor="factura">Nro Aprobación</label>
                    </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-md-3 col-lg-3 mb-4">
                    <div className="input-group floating">
                        <span className="span-icon material-symbols-outlined">phone</span>
                        <input disabled defaultValue={cliente.telefono}   name="telefono" id="telefono" type="text" className="form-control"/>
                        <label onDoubleClick={(e)=>e.preventDefault()} className={"label-floating"+(cliente.telefono!=="" ?" isFloting" : "")} htmlFor="telefono">Teléfono</label>
                    </div>
                </div>
            </div>
        </>

    );
}

export default GestionInfClient;