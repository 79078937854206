import "./Servicios.css"

const Servicios = ()=>{
    return(
        <div className="container mt-5">
            <h4 className="title-section">SERVICIOS</h4>
            <p className="mt-4">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla sapiente nostrum magni necessitatibus quasi saepe rerum error ipsam quibusdam! Incidunt fugiat mollitia similique molestiae eligendi vel inventore a esse vitae?</p>
            <div className="row mb-5 mt-5 justify-content-center">
                <div className="col-10 col-sm-6 col-md-6 col-lg-4">
                    <div className="card flex-row p-3">
                        <span className="material-symbols-outlined">data_exploration</span>
                        <div className="text-left pl-3">
                        <h5 className="mb-3">lorem ipsum</h5>
                        <p className="mt-4">Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
                        </div>
                    </div>
                </div>
                <div className="col-10 col-sm-6 col-md-6 col-lg-4">
                    <div className="card flex-row p-3">
                        <span className="material-symbols-outlined">menu_book</span>
                        <div className="text-left pl-3">
                        <h5 className="mb-3">Servi Sitema</h5>
                        <p className="mt-4">Minim veniam, quis nostrud ejercicio ullamco laboris nisi ut aliquip ex ea commodo consequat tarad limino ata</p>
                        </div>
                    </div>
                </div>
                <div className="col-10 col-sm-6 col-md-6 col-lg-4">
                    <div className="card flex-row p-3">
                        <span className="material-symbols-outlined">contract</span>
                        <div className="text-left pl-3">
                        <h5 className="mb-3">Sed ut perspiciatis</h5>
                        <p className="mt-4">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur</p>
                        </div>
                    </div>
                </div>
                <div className="col-10 col-sm-6 col-md-6 col-lg-4">
                    <div className="card flex-row p-3">
                        <span className="material-symbols-outlined">speed</span>
                        <div className="text-left pl-3">
                        <h5 className="mb-3">Magnífico Servi</h5>
                        <p className="mt-4">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui oficia deserunt mollit anim id est laborum</p>
                        </div>
                    </div>
                </div>
                <div className="col-10 col-sm-6 col-md-6 col-lg-4">
                    <div className="card flex-row p-3">
                        <span className="material-symbols-outlined">barcode_scanner</span>
                        <div className="text-left pl-3">
                        <h5 className="mb-3">Nemo Enim</h5>
                        <p className="mt-4">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque</p>
                        </div>
                    </div>
                </div>
                <div className="col-10 col-sm-6 col-md-6 col-lg-4">
                    <div className="card flex-row p-3">
                        <span className="material-symbols-outlined">groups</span>
                        <div className="text-left pl-3">
                        <h5 className="mb-3">Temporal de Eiusmod</h5>
                        <p className="mt-4">Et harum quidem rerum facilis est et expedita distintio. Nam libero tempore, cum soluta nobis est eligendi</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Servicios;